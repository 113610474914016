import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {

  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरू गजानन लीला स्तोत्र" />
        <HeaderGeneric title="श्री गुरू गजानन लीला स्तोत्र" adhyaay=''/>
        <div id="main">
          <section id="content" className="main">
            <p>
॥ श्री गजानन प्रसन्न ॥< br />
- श्री गणामहाराज कृत -< br />
श्री गुरू गजानन लीला स्तोत्र< br />
< br />
(प्रथमावृत्ती - गुरू पोर्णिमा दिनांक १८ जुलै १९८९)< br />
< br />
॥ प्रारंभ ॥
< br />
॥ श्री गजानन प्रसन्न ॥ श्री कुलदेवता प्रसन्न ॥< br />
॥ श्री गणामहाराजकृत श्री गजाननलीला स्तोत्र प्रारंभ ॥< br />
< br />
श्री गणेशाय नमः । हे गजानना विघ्नहर्ता । तू सुखकर्ता । तू दुःखहर्ता ॥ १ ॥ < br />
आधी करतो तुझे स्मरण । मग कुलदेवतेचे स्मरण । नंतर जन्मदात्याचे स्मरण । ज्यांनी शिकवले सदाचरण ॥ २ ॥ < br />
तसेच गुरू गजाननाचे स्मरण । श्री दासगणू महाराज स्मरण । गुरू आठवलेशास्त्री स्मरण । होण्या स्तोत्र सुरस लेखन ॥ ३ ॥< br /> 
हे कुलदेवते, तू कुल रक्षिते । माझ्या कुलाचे हित कशात ते । हे तू वारंवार जाणते । प्रार्थितो सावध करण्याते ॥ ४ ॥ < br />
मी तुझाच असल्यावर । का न मला सहाय्य लाभणार ? । गतजन्मीचा कर्मफलाचा भार । जेणे झालो भूवर भार ॥ ५ ॥ < br />
तो प्रवृत्त करतो मनाला । नाना विचार सतावे मजला । विविध कर्मे आचरणाला । तूच तार एकेक प्रसंगाला ॥ ६ ॥ < br />
आता तरी माझे हातून । ऐसी कृती घे करवून । जेणे घडेल सत्शील वर्तन । हीच प्रार्थना मनोमन ॥ ७ ॥ < br />
जे मिळत नाही वैभवात । त्याहुन अधिक चित्तशांतीत । पटे, त्यातच सर्व प्राप्त । उलटे होते आचरणात ॥ ८ ॥ < br />
चित्त शांतीसाठी । आनंद प्राप्तीसाठी । नाना चिंतने होती । तूच जाणते कशात प्राप्ती ॥ ९ ॥ < br />
तू जे जे मला सांगशील । ते ते सर्व श्रद्धेने होईल । मी आनंदानेच तृप्त होईल । तूच तृष्णा भागवशील ॥ १० ॥ < br />
आताच माझ्या कानात । तुझे स्वर ऐकू येतात । हितोपदेष ते वाटतात । तेच येऊ दे आचरणात ॥ ११ ॥ < br />
तू मजला सांगते । नमस्मरणे गुरूचरण लाभते । गुरू आज्ञेत शांती लाभते । प्रारब्ध सुसह्य होते ॥ १२ ॥ < br />
तुझी कृपा लाभली आम्हाला । जेणे संत गजानन लाभला । तो नित्य तत्पर कुल रक्षणाला । त्याच्याच वर्णन करतो लीला ॥ १३ ॥ < br />
ह्या गुरू गजाननाचे । हयातीचे व आज पर्यंतचे । अनेक चमत्कार लीलेचे । रक्षण करतो भक्तांचे ॥ १४ ॥ < br />
काही भक्तांचे अनुभव । काही सांगतो स्वानुभव । वाटे श्रवणाने जीव । तरुन जाईल भव ॥ १५ ॥ < br />
महाराष्ट्रात बुलढाणा जिल्ह्यात । खामगाव तालुक्यात । शेगाव नामे ग्रामात । गुरू गजानन प्रकटतात ॥ १६ ॥ < br />
शके अठराशेत । माघ वद्य सप्तमी सुमुहुर्त । पातुरकरांच्या घरात । होते सुनेची ऋतुशांत ॥ १७ ॥ < br />
त्याच दिवशी ऐन दुपारास । योगी आला त्यांच्या घरास । स्वये परमानंद घरास । आला जणु सांगण्यास ॥ १८ ॥ < br />
योगी तरुण वयाचा । उंच, सावळ्या रंगाचा । बालमन निरागस भावाचा । तेजःपुंज शरीरयष्टीचा ॥ १९ ॥ < br />
जुनी पुराणी बंडी अंगात । कमंडलू नि चिलिम त्याप्रत । बैसला उष्ट्या पत्रावळी जेवत । होता अन्न परब्रह्म खूण पटवत ॥ २० ॥ < br />
हे दृष्य बंकट, दामोदरे पाहिले । पातुरकरास सुचविले । पातुरकराने पंचपक्वान्न आणले । त्याने एकत्र करून उदर भरले ॥ २१ ॥ < br />
हाळाच्या पाण्याने भागविली तृष्णा । गढुळ निर्मळ भेद न मना । सर्वच सारखे योगी मना । पटवत होता एकेक खुणा ॥ २२ ॥ < br />
दुसर्‍या एका प्रसंगात । पायाचे तळवे भिजतील इतपत । पाणी नव्हते त्या ओढ्यात । पितांबरास धाडले त्याप्रत ॥ २३ ॥ < br />
तुंब्या बुडविता शुभ्र जले भरला । योग्याचा अधिकार कळला । बंकट, पितांबर त्याचा झाला । समर्थ चरणी माथा ठेवला ॥ २४ ॥ < br />
गोविंदबुवा किर्तनकारास । महाराजे केला उपदेश । आधी आणावे आचरणास । मग उपदेशावे सर्वांस ॥ २५ ॥ < br />
ईच्छाराम शेटजींकडे भोजन । अती आग्रहे अन्न प्राशन । उलटी झाली खणाणून । अती आग्रह वाईट दिले पटवून ॥ २६ ॥ < br />
काशीच्या गोसाव्याचा । हेतु ओळखला मनीचा । गांजाचा नवस फेडला त्याचा । हेतु पुरवला भक्ताचा ॥ २७ ॥ < br />
जानराव तापाने फणफणला । गंडांतराचा योग आला । तीर्थ अंगार्‍याने टाळला । संत टाळती गंडांतराला ॥ २८ ॥ < br />
गुरू नाही होणार लीप्त । वशील्यात, प्रसादात । विठोबा घाटोळ ढोंगी भक्त । सोटीने बडवले त्याप्रत ॥ २९ ॥ < br />
ठिणगी वाचून चिलिम पेटवली । अग्नीविणा अग्नी निर्मिती केली । योग लीलेची खूण पटवली । सारी मंडळी चकित झाली ॥ ३० ॥ < br />
जानकीरामे भंडारा केला । चिंचवण्यात आळ्या पडल्या । योगबळे त्या नष्ट केल्या । जानकीराम भानावर आला ॥ ३१ ॥ < br />
चंदुमुकिनाच्या घरातील कान्होले । अंतर्ज्ञाने जाणले । शिळे कान्होले खाल्ले । ताजे शिळे भेद न राहिले ॥ ३२ ॥ < br />
माधवास उपदेश । योग्य कर्म योग्य वेळेस । घनपाठी ब्राह्मण वसंतपूजेस । न बोलाविता येती समयास ॥ ३३ ॥ < br />
कोरड्या विहिरीत जल उत्पन्न केले । योग लीले भरते झाले । मधमाशांचे काटे सोसले । जशास तसे न वागावे पटवले ॥ ३४ ॥ < br />
हरी पाटील सशक्त पेहेलवान । महाराजांस कुस्तीचे केले आव्हान । महाराज कुस्तीत गेले जिंकुन । योग सामर्थ्य दिले पटवून ॥ ३५ ॥ < br />
दर्यापूर जवळील शिवर गावात । व्रजभूषण ब्राह्मण सूर्यभक्त । दर्शन देऊन आशीष देतात । कर्मात आसक्ती नसावी सांगतात ॥ ३६ ॥ < br />
उसाचा मार सोसला । पाठीवर एकही वळ न उठला । हाताने उसाचा रस पाटीलांना दिला । विशाल अंतःकरणा दावता झाला ॥ ३७ ॥ < br />
गुरूकृपा खंडु पाटीलास । पुत्ररत्न झाले त्यास । बेडीमुक्त केले त्यास । ठरला कोर्टात निर्दोष ॥ ३८ ॥ < br />
महाराज वेद पारंगत । तेलंगी ब्राह्मण झाले चकित । ब्रह्मगिरी पक्का ढोंगी वर्तनात । जळत्या प्रसंगे झाले पटवत ॥ ३९ ॥ < br />
द्वाड घोडे शांत केले । महाराज चौखुरात झोपले । गोविंदबुवास जागृत केले । नामजपाचे महत्व पटवले ॥ ४० ॥ < br />
‘तो’ ‘मी’ नसे वेगळा । बाळकृष्णास उपदेश केला । गणेश आप्पांच्या पूजेचा स्विकार केला । अंतर्ज्ञानाच्या खुणा पटवल्या ॥ ४१ ॥ < br />
भास्कराच्या ईर्षेस जाणले । बाळाभाऊस छत्रीने मारले । बाळाभाऊस वळ न उठले । भक्तित स्पर्धा नसावी, पटवले ॥ ४२ ॥ < br />
गायीचे आडदांडपणे । शांत झाले योगलीलेने । लक्ष्मणाचे पोट दुखणे । बरे केले उष्ट्या आंब्याने ॥ ४३ ॥< br />
दंभाचारे होतो पुण्यर्‍हास । पटवले त्यांनी लक्ष्मणास । पूर्व संचित न सुटले कुणास । पटवले भास्करास ॥ ४४ ॥< br /> 
कावळ्यांस वेदसार शिकविले । द्वेष मत्सर करू नये सांगितले । गणु जवर्‍यास वाचविले । सुरुंगातुन बाहेर काढले ॥ ४५ ॥ < br />
झिडकारले अलंकारास । जागे केले बच्चुलालास । पालवी फुटली वठलेल्या आंब्यास । रक्षिले त्यांनी पितांबरास ॥ ४६ ॥ < br />
करारापेक्षा जास्त जागा घेतली । सरकारी दंडाची माफी झाली । दंडाची रक्कम परत मिळली । चमत्कृतीची खुण पटवली ॥ ४७ ॥ < br />
गुरूकृपा योग गंगाभारतीस । बरे केले महारोगास । झ्यामसिंगाच्या भंडार्‍यास । घालवले पर्जन्यास ॥ ४८ ॥ < br />
पुंडलिकाची प्लेग गाठ । बरी केली शेगाव वारीत । बंडुतात्या सावकारी कर्जात । सुवर्ण मोहोरे झाला मुक्त ॥ ४९ ॥ < br />
प्रत्यक्ष नर्मदा आली दर्शनास । तिने रक्षिले सर्वांस । कृपाशिर्वाद लोकमान्यांस । अमर झाले गीतारहस्य ॥ ५० ॥ < br />
दिल्या पादुका पुंडलिकास । खरा गुरू दावला त्यास । थांबला तिसर्‍या प्रहरास । भाऊ कवराच्या पिठल भाकरीस ॥ ५१ ॥ < br />
तुक्याच्या कानात छरा गेला । औषधोपचार निकामा ठरला । मठ झाडल्याने बरा झाला । गुरूसेवेचा महिमा पटवला ॥ ५२ ॥ < br />
महाराजांच्या नागवेपणावर । फिर्याद झाली त्यावर । महाराज न ठरले गुन्हेगार । जिंकले ते योगबळावर ॥ ५३ ॥ < br />
बापुरावाच्या पत्नीला । भानामतीचा त्रास झाला । तीर्थ अंगारा उपयुक्त ठरला । गुरूकृपा आली फळाला ॥ ५४ ॥ < br />
महाराज अकोटास गेले । नरसिंगास भेटले । एकचित्ते विहिरीत डोकावले । तत्क्षणी झरे उत्पन्न झाले ॥ ५५ ॥ < br />
केली बायजेची अब्रु रक्षण । सुटली दीराच्या तडाख्यातुन । दिले बापुन्यास विठ्ठल दर्शन । त्याची तळमळ जाणून ॥ ५६ ॥< br /> 
भाऊ कवरास फोड झाला । औषधोपचार निकामा ठरला । धावुन गेले भक्त रक्षणाला । तिर्थ अंगारा उपयुक्त ठरला ॥ ५७ ॥ < br />
वारकर्‍यास कॉलरा झाला । स्पर्शाने त्यास बरा केला । भक्त रक्षिला गेला । पंढरीच्या वारीला ॥ ५८ ॥ < br />
रस्त्यात कुत्रे मेलेले । पदस्पर्शाने जिवंत केले । ब्राह्मणास सामर्थ्य कळले । त्याच्या शंकेचे निरसन झाले ॥ ५९ ॥ < br />
येतात धारचे रंगनाथ । वासुदेवानंद येतात । बाळाभाऊस सांगतात । अंतर्ज्ञानाची खूण पटवतात ॥ ६० ॥ < br />
काशिनाथ गर्दे दर्शनास येता । त्यास कोपरखळी झाले मारता । तारवाला दिसेल घरी जाता । सांगितले बदलीच्या वृत्ता ॥ ६१ ॥ < br />
कृपाशिर्वाद गोपाळबुटीस । पुत्ररत्न झाले त्यास । झोपेतुन उठवले तिमाजीस । गाढवापासुन रक्षिले शेतास ॥ ६२ ॥< br />
महाराज रस्त्यात दिगंबर दिसता । शिपाई त्यांस झाला मारता । शिपाई विपरीत झाला भोगता । घरदार नि स्वतःस झाला घालवता ॥ ६३ ॥< br />
संसारसुख हरी जाखड्यास । योगविद्या निमोणकरास । जाणले त्याच्या तळमळीस । पूर्ण केले मनोरथास ॥ ६४ ॥< br />
विसरू नये केलेला नवस । संगितले तुकाराम कोकाटेस । स्वयेच जाणला समाधी दिवस । सांगितले हरी पाटलास ॥ ६५ ॥< br />
स्वतःच्या हस्ते बाळाभाऊस । गादीवर बसविले त्यास । गुरूकृपा लाभली त्यास । पटविली सर्व भक्तांस ॥ ६६ ॥< br />
बाळाभाऊ निवर्तल्यावर । नांदुर्‍याचा नारायण गादीवर । त्याचाही कळला अधिकार । गुरूकृपा होती त्यावर ॥ ६७ ॥< br />
लक्ष्मण हरी जांजळास । दर्शन दिले बोरीबंदरास । पूर आला मन नदीस । रक्षिले माधव मार्तंड जोशास ॥ ६८ ॥< br />
यादव गणेश सुभेदारास । तोटा कापसाच्या व्यापारास । भिकार्‍याच्या रूपात दर्शन त्यास । व्यापारात फायदा झाला त्यास ॥ ६९ ॥< br />
भाऊ कवराची बदली तेल्हारी होता । शेगावी दर्शन झाला करता । व्यतीपातास न धाडले भक्ता । गुरू रक्षितात आपल्या भक्ता ॥ ७० ॥< br />
आली जयपुरची बाई उत्सवास । मुक्त झाली भूत पिशाच्च त्रासास । तीस फुटावरुन पडलेल्या गवंड्यास । तत्क्षणी झेलले त्यास ॥ ७१ ॥< br />
दिनकरास घेरले सोबणी रोगाने । बरा झाला शेरणी नवसाने । रामचंद्राच्या पत्नीचे दुखणे । बरे झाले प्रदक्षिणेने ॥ ७२ ॥< br />
रामचंद्र पाटिलास दर्शन । सांगितले त्यास निक्षुन । मठाचा कारभार तुझ्या हातुन । करावा तू मनोमन ॥ ७३ ॥< br />
बरेच अनुभव दर्शन । गणामहाराजास देऊन । पटवते झाले खुण । केले त्याचे रक्षण ॥ ७४ ॥< br />
एकोणिसशे सत्त्यात्तर सालात । मांजलपुरचे रहाते घरात । सायंकाळी तीन नाणी फेकतात । विस्मयचकित करतात ॥ ७५ ॥< br />
घरातले रस्त्यातले दिवे जाता । तत्क्षणी झाले प्रकटता । साशंक वृत्ती दूर करता । बसली गुरूवर दृढनिष्ठा ॥ ७६ ॥< br />
त्याच घरी सायंकाळी प्रसन्न झाली गुरूमाऊली । तसविरीतुन उजवा हात काढती झाली । आशीष देण्यास तत्पर झाली ॥ ७७ ॥< br />
रात्री साडे अकराच्या सुमारास । भूत पिशाच्चाने घेरले गणामहाराजास । माऊली धावली त्या समयास । रक्षिते झाले आपल्या भक्तास ॥ ७८ ॥< br />
केले भूतपिशाच्च पीडामुक्त । केले अल्सर व्याधी मुक्त । निघाले गुरुकृपेने भाडुत । रक्षण केले नोकरी प्रसंगात ॥ ७९ ॥< br />
महाराज म्हणे गुरू न कधी शोधावा । ऐसा नामजप करावा । गुरू स्वयेच घरी यावा । गणामहाराजास प्रत्यक्ष पुरावा ॥ ८० ॥< br />
एकणिसशे ऐंशीच्या गुरू पौर्णिमेस । सांगितले गणामहाराजास । विकावे मांजलपुरचे घरास । जावे मार्केट जवळ रहाण्यास ॥ ८१ ॥< br />
अमी अपार्टमेंटच्या रहात्या घरात । कुंकवाची पाऊले सर्व खोलींत । सांगे पांच वार्‍या कराव्यात । खुण झाले पटवत ॥ ८२ ॥< br />
गुरूवारची सेवा प्रिय मजला । आनंद वाटण्यात आनंद मला । नऊ कोटी मंत्रजपाला । करवते झाले संकल्पाला ॥ ८३ ॥< br />
१९७६ ते १९८५ पर्यंत । ठेवले नामजप पारायणात । सूर्योदय ते सूर्यास्ता पर्यंत । ८३ पासून दरवर्षी नाम सप्त्यात ॥ ८४ ॥< br />
२७ जुलै १९८५ त । आठवले गुरू लाभतात । काव्यशक्ती जागृत करतात । कृपाशिर्वाद सदा असतात ॥ ८५ ॥< br />
एकहजार आठ नारळाचे तोरण । पैसे नसताना घेतले बांधुन । शेगांवी मंदीरी पटवली खुण । पूर्व नियोजितापमाणे आले जुळून ॥ ८६ ॥< br />
भिकार्‍याच्या फकिराच्या रूपात । तर घरी कधी प्रकटतात । कधी स्पष्ट सांगतात । कधी सांकेतिक बोलतात ॥ ८७ ॥< br />
वारंवार दिसतात स्वप्नात । कधी उपदेश कानात । गुरफटु नको सभोवतालात । सदैव जागृत ठेवतात ॥ ८८ ॥< br />
आता सांगतो भक्तांस । अनुसरावे तुम्ही त्यास । ज्याला गुरूभेट तळमळ ध्यास । त्यालाच लाभतो गुरू सहवास ॥ ८९ ॥< br />
नामजपे प्राप्त कराल गुरूस । गुरू स्वयेच येतो घरास । प्रत्यक्ष अनुभव गणामहाराजास । रक्षितात वेळप्रसंगास ॥ ९० ॥< br />
जो राहील स्तोत्रपाठात । रक्षिला जाईल अब्रु प्रसंगात । स्तोत्र पठणाने चित्त शांत । चित्त शांतीतच सर्व प्राप्त ॥ ९१ ॥< br />
स्तोत्र पठणाने गुरूकृपा । परमार्थाचा मार्ग सोपा । तिन्ही त्रिकाळी नाम जपा । ह्याहुन दुसरा न मार्ग सोपा ॥ ९२ ॥< br />
नका विसरू नाम जपास । गण गण गणात बोते मंत्रास । जो जपेल ह्या मंत्रास । तो पात्र ठरेल दर्शनास ॥ ९३ ॥< br />
नामस्मरण करावे । त्यावेळी गुरूप्रसंग आठवावे । त्यातच तल्लीन व्हावे । आनंदास पात्र ठरावे ॥ ९४ ॥< br />
स्तोत्र पठण वेळी गुरूस आठवावे । कृपासावलीस पात्र ठरावे । ऐसे स्तोत्र पठण करावे । गुरू माऊलीने स्वयेच ऐकावे ॥ ९५ ॥< br />
गणामहाराज सांगे स्वानुभवाने । भक्ती करावी तळमळीने । सारे मिळते तळमळीने । सारे टिकते दृढ श्रद्धेने ॥ ९६ ॥< br />
नामस्मरणे गुरू प्राप्त । गुरू आज्ञेत चित्त शांत । चित्त शांतीत आनंद प्राप्त । आनंद प्राप्तीत सर्व प्राप्त ॥ ९७ ॥< br />
जैसे रक्षितात गणामहाराजास । तैसेच रक्षो सर्व भक्तांस । प्रार्थना करतो ईश्वरास । गुरू दर्शन देवोत सर्व भक्तांस ॥ ९८ ॥< br />
गुरू दर्शने आनंदतो जीव । गणामहाराजाचा स्वानुभव । असाच येवो सर्वांना अनुभव । वाटे तुम्ही तरुन जावा भव ॥ ९९ ॥< br />
देवदेवतांच्या कृपेने । गुरू गजानन कृपेने । गुरू आठवले शास्त्रींच्या कृपेने । झाले स्तोत्राचे लिहिणे ॥ १०० ॥< br />
हे स्तोत्र उपयुक्त ठरो भक्तांना । लाभो गुरूचरण भक्तांना । होवो प्रारब्ध सुसह्य भक्तांना । गणामहाराजाची विनम्र प्रार्थना ॥ १०१ ॥< br />
< br />
॥ श्री योगीराज गजानन महाराज की जय ॥< br />
< br />
- समाप्त -< br />
< br />
- गणामहाराज
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
